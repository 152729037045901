
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  /* background: linear-gradient(0deg, var(--background-color) 0%, color-mix(in srgb, var(--background-color) 90%, white 10%) 100%); */
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  /* background-image: url(''); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background: #0079C0; */
  
}
.hero .carousel{
  width: 100%;
}
.hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 75vh;
  padding-top: 60px;
}

.hero h2 {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  text-decoration: underline;
}

.hero p {
  max-width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  width: 10%;
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

.hero .btn-get-started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  animation-delay: 0.8s;
  color: var(--default-color);
  border: 2px solid var(--accent-color);
}

.hero .btn-get-started:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  text-decoration: none;
}

@media (min-width: 1024px) {
  .hero p {
    max-width: 60%;
  }

  .hero .carousel-control-prev,
  .hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 768px) {
  .hero .carousel-container {
    min-height: 90vh;
  }

  .hero h2 {
    font-size: 28px;
  }
}

.hero .hero-waves {
  display: block;
  width: 100%;
  height: 100px;
  position: relative;
}

.hero .wave1 use {
  animation: move-forever1 10s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.6;
}

.hero .wave2 use {
  animation: move-forever2 8s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
  opacity: 0.4;
}

.hero .wave3 use {
  animation: move-forever3 6s linear infinite;
  animation-delay: -2s;
  fill: var(--default-color);
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}


/* @media screen and (min-width: 320px) and (max-width: 767px) {
  .header{
    display: none !important;
  }
  .navbar-brand{
    width: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 2560px) {
  .mobile-nav{
    display: none;
  }
  .navbar-brand{
    width: 150px;
  }
} */

